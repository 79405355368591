// import React, { Component } from 'react';
import React, { useState, useEffect } from 'react';
import Info from '../Header/Info';
import logoLlikaBlanco from '../imagen/Logo-Empresa-LLIKA.png';
import logoLlikaAzul from '../imagen/Logo-Empresa-LLIKA1.png';
//import MenuInformacion from '../ToolBarInformacion/MenuInformacion';
import './toolBars.css'

export default function Header(props) {

  // Obtener el valor de la unidad rem en píxeles
  const remVal = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const comparationvalue = window.innerHeight - 99;


  // Estado booleano para cambiar los estilos de la barra de navegación
  const [shouldBgChange, setShouldBgChanged] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);

  // Efecto para verificar si la barra de navegación debería cambiar de estilo
  useEffect(() => {
    function onScroll() {
      const changeBackground = window.scrollY > (window.innerHeight - 5.5 * remVal);
      const hideNavBar = window.scrollY > (window.innerHeight - comparationvalue);

      if (shouldBgChange !== changeBackground) {
        setShouldBgChanged(changeBackground);
      }
      if (shouldHide !== hideNavBar) {
        setShouldHide(hideNavBar);
      }
    }
    document.addEventListener('scroll', onScroll);
  });

  return (
    <header className={'header ' + (shouldBgChange ? 'header--positive' : '')}>
      <section id='info' className={shouldBgChange ? 'header_comprobante' : ''}><Info EsComprobante={props.EsComprobante} /></section>
      <nav className="header__navbar topnav">
        <a href="/" className="logo">
          <img className="imagen_logo" src={shouldBgChange ? logoLlikaBlanco : logoLlikaBlanco} alt="LLIKAfact" ></img>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
        <ul className="menu">
          <li><a href="/">INICIO</a></li>
          <li><a href="/#Nosotros">NOSOTROS</a></li>
          <li><a href="/#Industrias">SISTEMAS</a></li>
          <li><a href="/#Beneficios">BENEFICIOS</a></li>
          <li><a href="/#Contacto">CONTACTANOS</a></li>
        </ul>
      </nav>
    </header>
  );
}
