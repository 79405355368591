import React from 'react';
import './footer.css';
import { faFacebook, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../imagen/Logo-Empresa-LLIKA.png';

export default function Footer() {
  return (
    <section id="Contacto">
      <footer>
        <div className='footer__content'>
          <div className="footer">
            <div className="footer_info">
              <div>
                <h2 className="footer_info_subtitulos"></h2>
                <div className="footer__divisiones">
                  <img className="footer__image" src={logo} />
                </div>
              </div>
              <div>
                <h2 className="footer_info_subtitulos">Contacto</h2>
                <div className="footer__divisiones">
                  <div className="footer__divisiones_Espacio">
                    <div>
                      <p >
                        <FontAwesomeIcon icon={faPhone} color="white" />
                        {' '}
                        +51 997 367 794
                      </p>
                    </div>
                    <div>
                      <a href="mailto:info@llikafact.comm">
                        <FontAwesomeIcon icon={faEnvelope} color="white" />
                        {' '}info@llikafact.com
                      </a>
                    </div>
                    <div>
                      <p>
                        <FontAwesomeIcon icon={faMapMarkerAlt} color="white" />
                        {' '}
                        Cusco - Perú
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h2 className="footer_info_subtitulos">Te Ofrecemos</h2>
                <div className="footer__divisiones">
                  <div className="footer__divisiones_Espacio">
                    <p>*Documentos ilimitados</p>
                    <p>*Conectado con SUNAT y RENIEC</p>
                    <p>*Gestión y control de ventas</p>
                    <p>*Soporte inmediato</p>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="footer_info_subtitulos">Redes </h2>
                <div className="footer__divisiones">
                  <div className="footer__divisiones_Espacio">
                    <div>
                      <a target="_blank" href="https://api.whatsapp.com/send?phone=51997367794&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20sistema%20.">
                        <FontAwesomeIcon icon={faWhatsapp} style={{ margin: '10px' }} size="2x" color="white" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://web.facebook.com/llikafact">
                        <FontAwesomeIcon icon={faFacebook} style={{ margin: '10px' }} size="2x" color="white" />
                      </a>
                    </div>
                    <div>
                      <a target="_blank" href="https://www.youtube.com/channel/UC-nPT1dnayiTuXhXLDMToBw?disable_polymer=true">
                        <FontAwesomeIcon icon={faYoutube} style={{ margin: '10px' }} size="2x" color="white" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='footer__divisiones-hr'>
              <hr />
            </div>
            <div className="copyrigth">
              <p>LLIKAfact  Copyright ©<script>document.write(new Date().getFullYear());</script>2022 Todos los derechos reservados</p>
            </div>
          </div>
        </div>
      </footer>
    </section>

  );
}
