import React from 'react'
import './nosotros.css'

//recursos
 import image1 from "../imagenes/bussines.png";

export default class Nosotros extends React.Component{
    render(){
        return(

            <section id="Nosotros">
                <div className="nosotros">
                    <div><h1>¿Por qué Nosotros?</h1></div>
                    <div className="nosotros__contenido">
                        <div>
                            <img className="nosotros__imagen"  src={image1} alt="facturacion electronica"/>
                        </div>

                        <div className="Nosotros__card">
                            <p><span>LLIKAfact</span> es un software de gestión y facturación electrónica cuenta
                            con todas las herramientas necesarias para que puedas tener un mayor control
                            en las ventas, mejorando el rendimiento y productividad en tu empresa o negocio.</p>
                            <p>Nosotros sabemos que todo empresario y micro empresario necesita un software que
                                le ayude a ahorrar tiempo al momento de emitir sus comprobantes electrónicos,
                                simultáneamente con el control y gestión de las ventas, compras, stock y contabilidad
                                con todos sus datos seguros y accesibles, es por ello que <span>LLIKAfact</span>,
                                cuenta con módulos y herramientas eficientes, sencillas y de fácil uso.</p>                                                 
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}