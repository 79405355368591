import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './inicio.css'

//recursos
import image1 from "./Imagenes/Banner2.jpg";
import image2 from "./Imagenes/Banner3.jpg";
import image3 from "./Imagenes/Banner1.jpg";

export default class Nosotros extends React.Component {
    render() {
        return (

            <section id="inicio">
                <div className='principal'>
                    <Carousel className='principal__contenido' interval={3000} showArrows={true} show autoPlay={true} showStatus={false} showThumbs={false} showIndicators={true} swipeable={false} infiniteLoop={true} stopOnHover ={false}>
                        <div className='principal__img'>
                            <img src={image1} />
                            <div className="animated principal_label">
                                <h1>Optimiza tu tiempo y gestión administrativa</h1>
                                <p>Evoluciona tu negocio, con LLIKAfact</p>
                            </div>
                        </div>
                        <div className='principal__img'>
                            <img src={image2} />
                            <div className="animated principal_label">
                                <h1>Servicio de Facturación Electronica</h1>
                                <p>Enfocate en hacer crecer tu negocio</p>
                            </div>
                        </div>
                        <div className='principal__img'>
                            <img src={image3} />
                            <div className="animated principal_label">
                                <h1>Ahorra Tiempo</h1>
                                <p>Con LLIKAfact, facturas + rapido</p>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </section>

        )
    }
}