//import { faBorderAll } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

function FeatureBox(props) {
    return (
        <div className='beneficios__box'>
            <div className='beneficios__box_front'>
                <div className='beneficios__box_front-h1'>
                    {props.title_front}
                </div>
                <div className='beneficios__box_front-circle'>
                    <img className='beneficios__box_front-image' src={props.image} />
                </div>
                <div className='beneficios__box_front-SubTitle'>
                    {props.subtitle_front}
                </div>
                <div className='beneficios__box_front-p'>
                    {props.content_front}
                </div>
            </div>
        </div>
    );
}

export default FeatureBox;