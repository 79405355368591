import React from 'react';
import './clientes.css';
import FeatureBox from './FeatureBox';
import allynhampy from './Imagenes/AllynHampy.png'
import apecsur from './Imagenes/Apecsur.png'
import ccarvel from './Imagenes/Ccarvel.png'
import centrika from './Imagenes/Centrika.png'
import compumech from './Imagenes/Compumech.png'
import conaros from './Imagenes/ConarosPart.png'
import displastisa from './Imagenes/Diplastisa.png'
import driplast from './Imagenes/Driplast.png'
import elemperador from './Imagenes/ElEmperador.png'
import eppsinsegmet from './Imagenes/EppsImsegmet.png'
import eurobar from './Imagenes/eurobar.png'
import farmaciacorazonjesus from './Imagenes/FarmaciaCorazonJesus.png'
import gonzatours from './Imagenes/GonzaTours.png'
import hualmarket from './Imagenes/HualMarket.png'
import hubergym from './Imagenes/HuberGym.png'
import intiplast from './Imagenes/IntiPlast.png'
import inverliana from './Imagenes/InverLiana.png'
import invermelin from './Imagenes/InverMariaKarolina.png'
import invermariakarolina from './Imagenes/InverMelin.png'
import malus from './Imagenes/Malus.png'
import maralesa from './Imagenes/Maralesa.png'
import moncat from './Imagenes/Moncat.png'
import natividad from './Imagenes/Natividad.png'
import perucourmi from './Imagenes/PeruCuormi.png'
import perudrip from './Imagenes/PeruDrip.png'
import progreso from './Imagenes/Progreso.png'
import pumaplast from './Imagenes/PumaPlast.png'
import royerplaza from './Imagenes/RoyerPlaza.png'
import rutasandinas from './Imagenes/RutasAndinas.png'
import santafe from './Imagenes/SantaFe.png'
import toursmegantoni from './Imagenes/ToursMegantoni.png'
import tupayachi from './Imagenes/Tupayachi.png'
import waykisito from './Imagenes/Waykisito.png'
import yichen from './Imagenes/Yichen.png'
import Slider from "react-slick";

class Clientes extends React.Component {
    state = {}
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <section id="Clientes" >
                <div className="clientes">
                    <div className="clientes__content">
                        <h1>Algunos de nuestros clientes </h1>
                    </div>
                    {/*<div className='clientes__card'>
                        <FeatureBox image={allynhampy} />
                        <FeatureBox image={apecsur} />
                        <FeatureBox image={ccarvel} />
                        <FeatureBox image={centrika} />
                        <FeatureBox image={compumech} />
                        <FeatureBox image={conaros} />
                        <FeatureBox image={displastisa} />
                        <FeatureBox image={driplast} />
                        <FeatureBox image={elemperador} />
                        <FeatureBox image={eppsinsegmet} />
                        <FeatureBox image={eurobar} />
                        <FeatureBox image={farmaciacorazonjesus} />
                        <FeatureBox image={gonzatours} />
                        <FeatureBox image={hualmarket} />
                        <FeatureBox image={hubergym} />
                        <FeatureBox image={intiplast} />
                        <FeatureBox image={inverliana} />
                        <FeatureBox image={invermelin} />
                        <FeatureBox image={invermariakarolina} />
                        <FeatureBox image={malus} />
                        <FeatureBox image={maralesa} />
                        <FeatureBox image={moncat} />
                        <FeatureBox image={natividad} />
                        <FeatureBox image={perucourmi} />
                        <FeatureBox image={perudrip} />
                        <FeatureBox image={progreso} />
                        <FeatureBox image={pumaplast} />
                        <FeatureBox image={royerplaza} />
                        <FeatureBox image={rutasandinas} />
                        <FeatureBox image={santafe} />
                        <FeatureBox image={toursmegantoni} />
                        <FeatureBox image={tupayachi} />
                        <FeatureBox image={waykisito} />
                        <FeatureBox image={yichen} />
        </div>*/}
                    <div className='clientes__card'>
                        <Slider {...settings}>
                        <FeatureBox image={allynhampy} />
                        <FeatureBox image={apecsur} />
                        <FeatureBox image={ccarvel} />
                        <FeatureBox image={centrika} />
                        <FeatureBox image={compumech} />
                        <FeatureBox image={conaros} />
                        <FeatureBox image={displastisa} />
                        <FeatureBox image={driplast} />
                        <FeatureBox image={elemperador} />
                        <FeatureBox image={eppsinsegmet} />
                        <FeatureBox image={eurobar} />
                        </Slider>
                        <br/>
                        <Slider {...settings}>
                        <FeatureBox image={farmaciacorazonjesus} />
                        <FeatureBox image={gonzatours} />
                        <FeatureBox image={hualmarket} />
                        <FeatureBox image={hubergym} />
                        <FeatureBox image={intiplast} />
                        <FeatureBox image={inverliana} />
                        <FeatureBox image={invermelin} />
                        <FeatureBox image={invermariakarolina} />
                        <FeatureBox image={malus} />
                        <FeatureBox image={maralesa} />
                        <FeatureBox image={moncat} />
                        </Slider>
                        <br/>
                        <Slider {...settings}>
                        <FeatureBox image={natividad} />
                        <FeatureBox image={perucourmi} />
                        <FeatureBox image={perudrip} />
                        <FeatureBox image={progreso} />
                        <FeatureBox image={pumaplast} />
                        <FeatureBox image={royerplaza} />
                        <FeatureBox image={rutasandinas} />
                        <FeatureBox image={santafe} />
                        <FeatureBox image={toursmegantoni} />
                        <FeatureBox image={tupayachi} />
                        <FeatureBox image={waykisito} />
                        <FeatureBox image={yichen} />
                        </Slider>
                    </div>

                </div>
            </section>
        );
    }
}

export default Clientes;