import React from 'react';
import './contactanos.css'
import correo from '../imagenes/correo4.jpg'
import { faFacebook, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Contactanos extends React.Component {
    render() {
        return (
            <section id="Contacto" >
                <div className="contactanos">
                    <div>
                        <h1>Contáctanos</h1>
                    </div>
                    <div className='contactanos__content'>
                        <div className="contactanos__card">
                            <img className="contactanos__card_imagen" src={correo} alt="facturacion electronica" />
                            <div className='contactanos__card_texto'>
                                <h1>Solicite una demostración guiada</h1>
                                <br />
                                <p>Escribenos por whatsapp y coordinemos la hora y dia para la demo. solo tomara 20 minutos y sera virtual
                                </p>
                            </div>
                        </div>
                        <div>
                            <a className='contactanos__card_button' target="_blank" href="https://api.whatsapp.com/send?phone=51997367794&amp;text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20sistema%20.">
                            <FontAwesomeIcon icon={faWhatsapp}  style={{ margin: '10px' }} size="2x" color="white" /> Agendar reunión
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}