//import React, {useState, Fragment} from 'react';
import React, { useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


import './formulario.css';
import Modal from 'react-modal';
import { faL } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    height: '700px'
  }
};

const ComprobantesDownload = (props) => {


  const [ruc, setRuc] = useState('');
  const [date, setDate] = useState('');
  const [serie, setSerie] = useState('');
  const [numero, setNumero] = useState('');
  const [tipoComprobante, setTipoComprobante] = useState('01');
  const [verified, setverified] = useState(false);

  const callback = function () {
    console.log('Done!!!!');
  };

  const verifyCallback = function (response) {
    if (response) {
      setverified(true);
    }
  };

  let recaptchaInstance;

  const resetRecaptcha = () => {
    recaptchaInstance.reset();
  };

  const TipoSelectItems = [
    { label: 'Factura Electrónica', value: '01' },
    { label: 'Boleta de Venta Electrónica', value: '03' },
    { label: 'Nota de Crédito Electrónica', value: '07' },
    { label: 'Nota de Débito Electrónica', value: '08' },
    { label: 'Guía de Remisión Electrónica', value: '09' }
  ];

  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }


  const toInputUppercase = (e) => {
    e.target.value = (`${e.target.value}`).toUpperCase();
  };

  function ponerCeros(number, width) {
    const numberOutput = Math.abs(number); /* Valor absoluto del número */
    const { length } = number.toString(); /* Largo del número */
    const zero = '0'; /* String de cero */

    if (number < 0) {
      return (`-${zero.repeat(width - length)}${numberOutput.toString()}`);
    }
    //return ((zero.repeat(width - number)) + numberOutput.toString());
    return numberOutput.toString().padStart(8, '0')
  }
  function fileExists(url) {
    /*var http = new XMLHttpRequest();
    http.open('HEAD', path, false);
    http.send();
    if (http.status != 404)
      return true;
    else
      return true;
      */

   /* try {
      const iRespuesta = false;
      fetch(url, {
        method: 'HEAD',
        cache: 'no-cache'
      }).then((response) => {
        if (response.status === 200)
        iRespuesta = true;
      else
        iRespuesta = false;
      });

      return iRespuesta;
    }
    catch (error) {
      return false;
    }*/
    return true;
  }
  function downloadPDF() {
    if (ruc === '') {
      document.getElementById("ruc").style.border = "2px solid #f00";
      alert('Ingrese el ruc');

      return false;
    }
    if (date === '') {
      document.getElementById("date").style.border = "2px solid #f00";
      alert('Ingrese la fecha');
      return false;
    }
    if (serie === '') {
      document.getElementById("serie").style.border = "2px solid #f00";
      alert('Ingrese la serie');
      return false;
    }
    if (numero === '') {
      document.getElementById("numero").style.border = "2px solid #f00";
      alert('Ingrese el numero');
      return false;
    }

    if (verified === false) {
      alert('Por favor haga clic en el Recaptcha ');
      return false;
    }

    const fechaAct = date;
    const splitDate = fechaAct.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const ano = splitDate[0];
    const mes = splitDate[1];
    const dia = splitDate[2];
    const num = ponerCeros(numero, 8);
    const archivo = `${ruc}-${tipoComprobante}-${serie}-${num}.pdf`

    // let tipo='PDF'
    let url = `https://llikafact.com/llika/empresa/${ruc}/comprobantes/${ano}/${mes}/${dia}/PDF/${archivo}`;
    if (!fileExists(url)) {
      alert('El archivo no se encuentra disponible, solicitelo.');
      return false;
    }

    console.log(verified);
    return document.getElementById('pdf').src = url;
  }

  function downloadXML() {
    if (ruc === '') {
      document.getElementById("ruc").style.border = "2px solid #f00";
      alert('Ingrese el ruc');

      return false;
    }
    if (date === '') {
      document.getElementById("date").style.border = "2px solid #f00";
      alert('Ingrese la fecha');
      return false;
    }
    if (serie === '') {
      document.getElementById("serie").style.border = "2px solid #f00";
      alert('Ingrese la serie');
      return false;
    }
    if (numero === '') {
      document.getElementById("numero").style.border = "2px solid #f00";
      alert('Ingrese el numero');
      return false;
    }

    if (verified === false) {
      alert('Por favor haga clic en el Recaptcha');
      return false;
    }

    const fechaAct = date;
    const splitDate = fechaAct.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const ano = splitDate[0];
    const mes = splitDate[1];
    const dia = splitDate[2];
    const num = ponerCeros(numero, 8);
    const ruta = `${ano}/${mes}/${dia}/XML/${ruc}-${tipoComprobante}-${serie}-${num}.zip`

    let url = `https://llikafact.com/llika/empresa/${ruc}/comprobantes/${ruta}`;
    if (!fileExists(url)) {
      alert('El archivo no se encuentra disponible, solicitelo.');
      return false;
    }

    console.log(verified);
    return window.location.href = url;
  }
  function downloadCDR() {

    if (ruc === '') {
      document.getElementById("ruc").style.border = "2px solid #f00";
      alert('Ingrese el ruc');

      return false;
    }
    if (date === '') {
      document.getElementById("date").style.border = "2px solid #f00";
      alert('Ingrese la fecha');
      return false;
    }
    if (serie === '') {
      document.getElementById("serie").style.border = "2px solid #f00";
      alert('Ingrese la serie');
      return false;
    }
    if (numero === '') {
      document.getElementById("numero").style.border = "2px solid #f00";
      alert('Ingrese el numero');
      return false;
    }
    if (verified === false) {
      alert('Por favor haga clic en el Recaptcha ');
      return false;
    }

    const fechaAct = date;
    const splitDate = fechaAct.split('-');
    if (splitDate.count === 0) {
      return null;
    }
    const ano = splitDate[0];
    const mes = splitDate[1];
    const dia = splitDate[2];
    const num = ponerCeros(numero, 8);
    const ruta = `${ano}/${mes}/${dia}/CDR/R-${ruc}-${tipoComprobante}-${serie}-${num}.zip`


    let url = `https://llikafact.com/llika/empresa/${ruc}/comprobantes/${ruta}`;
    if (!fileExists(url)) {
      alert('El archivo no se encuentra disponible, solicitelo.');
      return false;
    }

    console.log(verified);
    return window.location.href = url;
  }

  return (
    <div className='formulario_global'>
      <h1 className='formulario-h1'>DESCARGA TU COMPROBANTE</h1>
      <div className="formulario">
        <form className="formulario__content">
          <div className="formulario__filas">
            <div className='formulario__columnas_input'>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon formulario__span">RUC Emisor</span>
                <InputText id="ruc" className="formulario__input2" name="ruc" placeholder="Ingrese su Ruc" minLength={11} maxLength={11} value={ruc} onChange={(e) => setRuc(e.target.value)} required />
              </div>
            </div>

            <div className='formulario__columnas_input'>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon formulario__span">Fecha Emisión</span>
                <InputText id="date" className="formulario__input2" type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
              </div>
            </div>

            <div className='formulario__columnas_input'>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon formulario__span">Comprobante</span>
                <Dropdown className="formulario__input2" options={TipoSelectItems} value={tipoComprobante} onChange={(e) => setTipoComprobante(e.target.value)} />
              </div>
            </div>

            <div className='formulario__columnas_input'>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon formulario__span">Serie-Número</span>
                <InputText id="serie" className="formulario__input" name="serie" placeholder="Serie" value={serie} onChange={(e) => setSerie(e.target.value)} minLength="4" maxLength="4" onInput={toInputUppercase} required />
                <span className="p-inputgroup-addon" >-</span>
                <InputText id="numero" className="formulario__input" name="numero" placeholder="Número" value={numero} onChange={(e) => setNumero(e.target.value)} required />
              </div>
            </div>
          </div>

        </form>
        <div className='formulario__catcha'>
          <div>
            <Recaptcha
              sitekey="6Le5Su4UAAAAAJ0_mitQYj49QpkbkJOYQbNxzI2b"
              //sitekey="6LcZhA4gAAAAAPLAMmDmpL7Add9vaEQbFPeL_Ifp"
              render="explicit"
              onloadCallback={callback}
              ref={e => recaptchaInstance = e}
              verifyCallback={verifyCallback}
              elementID="g-recaptcha"
              hl='es'
            />
          </div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <h2 ref={_subtitle => (subtitle = _subtitle)}></h2>
            {ruc.length === 0 || date.length === 0 || serie.length === 0 || numero.length === 0 || verified === false ? <h3>DATOS NO RELLENADOS, COMPLETARLOS</h3> : <embed id="pdf" src="#" type="application/pdf" width="100%" height="600px" />}
            <Button onClick={closeModal} label="Cerrar" className="p-button-rounded p-button-danger" />
          </Modal>
        </div>

        <div className="formulario__columnas">
          <div>
            <Button label="Ver PDF" onClick={openModal} onBlur={() => downloadPDF()} icon="pi pi-eye" className="button-raised p-button-info formulario__columnas_style" />
          </div>
          <div>
            <Button label="Descargar XML" onClick={() => downloadXML()} onBlur={resetRecaptcha} icon="pi pi-download" className="button-raised p-button-success formulario__columnas_style" />
          </div>
          <div>
            <Button label="Descargar CDR" onClick={() => downloadCDR()} onBlur={resetRecaptcha} icon="pi pi-download" className="button-raised p-button-danger formulario__columnas_style" />
          </div>
        </div>

      </div></div>
  )


}

export default ComprobantesDownload;