import React from 'react';
//import { Card } from 'primereact/card';
import './beneficios.css';
import documentos from './Imagenes/Documentos.png'
import ventas from './Imagenes/Ventas.png'
import egresos from './Imagenes/Gastos.png'
import inventario from './Imagenes/Inventario.png'
import cajas from './Imagenes/Cajas.png'
import usuarios from './Imagenes/Usuarios.png'
import clienteproveedor from './Imagenes/Clientes.png'
import personaliza from './Imagenes/Diseno.png'
import reportes from './Imagenes/Reportes1.png'
import FeatureBox from './FeatureBox';
export default class Beneficios extends React.Component {

    render() {        
        const content_Documentos = (
            <div>
                <li>Boletas y Facturas</li>
                <li>Notas de Venta</li>
                <li>Cotizaciones</li>
                <li>Nota de credito/debito</li>
                <li>Guia de remision</li>
                <li>Impresion A4, A5 y ticket</li>
                <li>Documentos ilimitados</li>
            </div>
        );
        const content_Ventas = (
            <div>
                <li>Ventas</li>
                <li>Formas de pago</li>
                <li>Cuentas por cobrar</li>
                <li>Cotizaciones</li>
                <li>Formalizacion de documentos</li>
                <li>Formatos pdf, xml, cdr</li>
            </div>
        );
        const content_Egresos = (
            <div>
                <li>Registro de egresos</li>
                <li>Registro de compras</li>
                <li>Cuentas por pagar</li>
                <li>Registro de gastos</li>
            </div>
        );
        const content_Inventario = (
            <div>
                <li>Entrada de productos con facturas, boletas y notas</li>
                <li>Entrada y salidas entre almacenes</li>
                <li>Ajuste de inventario</li>
                <li>Control de series y lotes</li>
                <li>Utilidades</li>
                <li>Tipos de precio</li>
                <li>Cambios de precio</li>
            </div>
        );
        const content_Cajas = (
            <div>
                <li>Control de caja diaria</li>
                <li>Control de turnos</li>
                <li>Transferencia de efectivo entre cajas</li>
                <li>Recepcion de efectivo</li>
                <li>Apertura y cierre de turnos</li>
                <li>Multiple vendedores</li>
                <li>Detalle diario</li>
            </div>
        );
        const content_Usuarios = (
            <div>
                <li>Datos de reniec</li>
                <li>Permisos por usuario</li>
                <li>Cajas por usuario</li>
                <li>Registro de procesos por usuario</li>
                <li>Apertura y cierre de caja por usuario</li>
                <li>Cuadre de caja por usuario</li>
                <li>Denegar procesos por usuario</li>
            </div>
        );
        const content_ClienteProveedor = (
            <div>
                <li>Datos de reniec</li>
                <li>Datos de sunat</li>
                <li>Creditos</li>
            </div>
        );
        const content_Peronaliza = (
            <div>
                <li>Configura tu logo</li>
                <li>Datos de tu empresa</li>
                <li>Color distintivo de tu empresa</li>
                <li>Diseño de comprobantes</li>
            </div>
        );
        const content_Reportes = (
            <div>
                <li>Ventas</li>
                <li>Compras</li>
                <li>Cuentas por cobrar y pagar</li>
                <li>Gastos</li>
                <li>Stock por almacen</li>
                <li>Arqueo de cajas</li>
                <li>Otros reportes</li>
            </div>
        );

        return (
            <section id="Beneficios" >
                <div className="beneficios">
                    <div className="beneficios__content">
                        <h1>Beneficios para tu Empresa </h1>
                    </div>
                    <div className='beneficios__card'>
                        <FeatureBox image={documentos} title_front='DOCUMENTOS' subtitle_front='Emite documentos en tiempo real' content_front = {content_Documentos}/>
                        <FeatureBox image={ventas} title_front='VENTAS' subtitle_front='Emite tus comprobantes desde donde te encuentres' content_front ={content_Ventas}/>
                        <FeatureBox image={egresos} title_front='EGRESOS' subtitle_front='Controle los gastos de tu negocio' content_front={content_Egresos}/>
                        <FeatureBox image={inventario} title_front='INVENTARIO' subtitle_front='Gestiona y controla tu stock' content_front={content_Inventario} />
                        <FeatureBox image={cajas} title_front='CAJAS' subtitle_front='Lleva un control diario de tus puntos de venta' content_front={content_Cajas}/>
                        <FeatureBox image={usuarios} title_front='USUARIOS' subtitle_front='Crea usuarios ilimitados' content_front={content_Usuarios}/>
                        <FeatureBox image={clienteproveedor} title_front='CLIENTES Y PROVEEDORES' subtitle_front='Extrae y guarda datos en tiempo real' content_front={content_ClienteProveedor}/>
                        <FeatureBox image={personaliza} title_front='PERSONALIZA' subtitle_front='Puedes color el logo de tu empresa y color' content_front={content_Peronaliza}/>
                        <FeatureBox image={reportes} title_front='REPORTES' subtitle_front='Genera reportes de cada una de las herramientas' content_front={content_Reportes}/>
                    </div>
                </div>
            </section>
        )
    }
}