import React from 'react';
//import { Card } from 'primereact/card';
import './industrias.css'
import servicentro from './Imagenes/Servicentro.png'
import ferreteria from './Imagenes/Ferreteria.png'
import market from './Imagenes/Market.png'
import transporte from './Imagenes/Transporte.png'
import epps from './Imagenes/Epps.png'
import restaurante from './Imagenes/Restaurante.png'
import autoparte from './Imagenes/Autoparte.png'
import comercio from './Imagenes/Comercio.png'
import farmacia from './Imagenes/Farmacia.png'
import bares from './Imagenes/Bares.png'
import hospedaje from './Imagenes/Hospedaje.png'
import tecnologia from './Imagenes/Tecnologia.png'
import FeatureBox from './FeatureBox';

export default class Industrias extends React.Component {
    state = {}
    render() {
        const Servicentro = (
            <img className="industrias__imagen" src={servicentro} alt="servicentro" />
        )
        const Ferreterias = (
            <img className="industrias__imagen" src={ferreteria} alt="ferreteria" />
        )
        const Epps = (
            <img className="industrias__imagen" src={epps} alt="epps" />
        )
        const Market = (
            <img className="industrias__imagen" src={market} alt="market" />
        )
        const Transporte = (
            <img className="industrias__imagen" src={transporte} alt="transporte" />
        )
        const Restaurante = (
            <img className="industrias__imagen" src={restaurante} alt="restaurante" />
        )
        const Autoparte = (
            <img className="industrias__imagen" src={autoparte} alt="autoparte" />
        )
        const Comercio = (
            <img className="industrias__imagen" src={comercio} alt="comercio" />
        )
        const Farmacia = (
            <img className="industrias__imagen" src={farmacia} alt="farmacia" />
        )

        const contenido_servicentro = (
            <div>
                <li>Personalización de datos y logo de tu empresa.</li>
                <li>Actualizaciones totalmente gratis.</li>
                <li>Emisión de comprobante por combustible.</li>
                <li>Venta rápida con premociones.</li>
                <li>Serafín.</li>
                <li>Arqueo de islas por medida de contometros.</li>
                <li>RIC. </li>
                <li>Apertura y cierre de caja en puntos de venta.</li>
                <li>Conectado con RENIEC y SUNAT.</li>
                <li>Control de inventario y kardex.</li>
                <li>Envio de PDF, XMLy CDR por email.</li>
                <li>Exporta reportes en EXCEL, PDF, WORD y otros.</li>
            </div>
        );

        return (
            <section id='Industrias'>
                <div className='industrias'>
                    <div className="industrias__content">
                        <h1>Nuestros Sistemas</h1>
                    </div>
                    <div className='industrias__card'>
                        <FeatureBox title_front='SERVICENTRO' image={servicentro} title_back='Servicentro' content_back={contenido_servicentro} />
                        <FeatureBox title_front='FERRETERIA' image={ferreteria} title_back='Ferreteria' />
                        <FeatureBox title_front='EPPS' image={epps} title_back='Epps' />
                        <FeatureBox title_front='MARKET' image={market} title_back='Market' />
                        <FeatureBox title_front='COMERCIO' image={comercio} title_back='Comercio' />
                        <FeatureBox title_front='AUTOPARTE' image={autoparte} title_back='Autoparte' />
                        <FeatureBox title_front='FARMACIA' image={farmacia} title_back='Farmacia' />
                        <FeatureBox title_front='RESTAURANTE' image={restaurante} title_back='Restaurante' />
                        <FeatureBox title_front='BARES' image={bares} title_back='Transporte' />
                        <FeatureBox title_front='HOSPEDAJE' image={hospedaje} title_back='Transporte' />
                        <FeatureBox title_front='TECNOLOGIA' image={tecnologia} title_back='Transporte' />
                        <FeatureBox title_front='TRANSPORTE' image={transporte} title_back='Transporte' />
                    </div>
                </div>
            </section>
        )
    }
}