import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
//Paginas

// import Home from './Paginas/Home/Home';
// import MenuPrincipal from './Componentes/ToolBar/ToolBar'
import Home from '../App/Paginas/Home/Home';
import Comprobantes from '../App/Paginas/Comprobantes/Comprobantes';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" >
                    <Home />
                </Route>
                <Route exact path="/comprobantes" >
                    <Comprobantes />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default App;