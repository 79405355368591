//import { faBorderAll } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

function FeatureBox(props) {
    return (
        <div className='clientes__box'>
            <div className='clientes__box_front'>
            <img className='clientes__box_front-image' src={props.image} />
            </div>
        </div>
    );
}

export default FeatureBox;